.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6); /* Ajusta la opacidad */
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.divEvents:hover .overlay {
  opacity: 1;
}

.description {
  text-align: center;
  padding: 20px;
}
.carousel {
  height: 390px !important;
  object-fit: cover !important;
}

.carousel img {
  transition: transform 0.9s;
  height: 390px !important;
  object-fit: cover !important;
}

.thumb {
  display: none !important;
}

.carousel-root {
  max-height: 390px !important;
  overflow: hidden;
}
