/* Main heading styles */
.main-heading {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Filter and search container styles */
.filter-search-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Filter container styles */
.filter-container {
  flex: 1;
  margin-right: 10px;
}

/* Search container styles */
.search-container {
  flex: 1;
}

/* Filter label styles */
label {
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
}

/* Select dropdown styles */
select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
}

/* Search input styles */
#search-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

/* Table styles */
.data-table {
  width: 100%;
  border-collapse: collapse;
}

/* Table header styles */
.column-header {
  font-size: 18px;
  font-weight: bold;
  background-color: #f0f0f0;
  padding: 10px;
  text-align: left;
}

/* Table data row styles */
.data-row:nth-child(even) {
  background-color: #f9f9f9;
}

/* Table data cell styles */
.data-cell {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

/* Pagination buttons styles */
.pagination-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/* Pagination button styles */
.pagination-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #d12421;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 5px;
}

/* Disabled pagination button styles */
.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

#divDigital {
  margin-left: -5%;
}

.btn-group .undefined {
  background-color: transparent !important;
  color: #000 !important;
  border: 0 !important;
}

@media only screen and (min-width: 720px) and (max-width: 800px) {
  #divDigital {
    margin-top: 6% !important;
  }
}

@media only screen and (max-width: 480px) {
  #divDigital {
    margin: -15% !important;
    min-height: 350px;
  }

  #divAlphabet .btn-transparent {
    font-size: 13px;
  }

  .titleInfoInteres {
    margin-top: 10% !important;
    margin-left: 35px !important;
  }
}

@media only screen and (max-width: 480px) {
  #divDigital {
    margin: -15% !important;
    min-height: 350px;
  }
}

.titleMasAccedidos {
  color: #646567;
  font-size: 1.3rem;
}

@media only screen and (min-width: 1198px) {
  .listMasAccedidos {
    display: flex; /* Mostrar los elementos en línea */
    padding: 0; /* Eliminar padding del <ul> */
    margin: 0; /* Eliminar margen del <ul> */
  }

  .itemMasAccedidos {
    margin-right: auto; /* Espacio entre los elementos */
    font-size: 1.2rem;
    font-weight: bold;
  }
}
