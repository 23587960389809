.containerBtn {
  justify-content: space-between;
  flex-direction: column;
  display: flex;
}

.titleAnnounc {
  color: #646567;
  font-size: 1.8rem;
}

.containerBtn .btnAnnounc {
  justify-content: flex-end;
  display: flex;
}

.divdescAnnounc {
  text-align: justify;
  width: 400px;
  white-space: initial;
}

.owl-nav {
  display: block !important;
}

.owl-carousel {
  height: 100%;
  max-height: 100%;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  z-index: 1;
  width: 50px;
  height: 50px;
  background-color: #d12421 !important;
  color: white !important;
  font-weight: bold !important;
  font-size: 20px !important;
}

.owl-item.active:hover .btnAnnounc {
  background-color: #d12421;
  color: white;
}

.divAnnounc {
  min-height: 350px !important;
}

.divAnnounc img {
  max-width: 100%;
  transition: all 0.3s;
  display: block;
  width: 100%;
  height: 250px;
  transform: scale(1);
  object-fit: cover;
}

.divAnnounc:hover img {
  -ms-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

@media only screen and (max-width: 1320px) {
  .divdescAnnounc {
    display: none;
  }
}

@media only screen and (min-width: 778px) and (max-width: 1000px) {
  .divdescAnnounc {
    display: block;
  }
}

@media only screen and (max-width: 1420px) {
  .divdescAnnounc {
    width: 380px;
  }

  .slick-next {
    padding-right: 7.5% !important;
  }
}

.descriptionEvent {
  white-space: pre-line;
}
