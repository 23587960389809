@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    background: #fff;
}

.underline {
    color: #d12421;
}

a {
    text-decoration: none !important;
}

.href,
.card-link {
    color: #d12421 !important;
    font-weight: bold;
}

a:hover {
    color: #646567 !important;
}

.barnav {
    background-color: #F0F0F0 !important;
    z-index: 99 !important;
}

.MuiButton-root{
    color: #646567 !important;
}

.barnav .active{
    background-color: #C9CCD2 !important;
}

.bg-red {
    background-color: #d12421 !important;
}

.size404{
    font-size: 80px !important;
    color: #d12421 !important;
    font-weight: bold !important;
    padding-top: 50px !important;
}

.padding404{
    padding-top: 9.7% !important;
}

@media only screen and (max-width: 520px) {
    .barnav {
        overflow: scroll;
        max-height: 500px;
    }
}