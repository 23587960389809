.accordion-button {
  color: #d12421 !important;
  font-weight: bold;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.35);
}

#containerRes {
  background-image: url("/src/assets/images/help/background.png");
  width: 100% !important;
  height: 350px !important;
  background-repeat: no-repeat !important;
  object-fit: cover !important;
}

#containerRes #tituloRes {
  background-color: #646567;
  color: white;
  width: 570px;
  margin: 5%;
  border: 1px solid #d12421;
  position: absolute;
  font-size: 22px;
}

@media only screen and (max-width: 480px) {
  #containerRes {
    height: 110px !important;
    background-color: #d12421 !important;
    width: 100% !important;
  }

  #containerRes #tituloRes {
    width: auto;
    margin: 10%;
    border: 1px solid #d12421;
    position: absolute;
    font-size: 16px;
  }
}
