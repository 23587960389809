.divBook {
  width: 90%;
  margin: auto;
}

.slider_title {
  font-size: 5vw;
  color: #d12421;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 2vw;
}

.titleDivBook {
  color: #646567;
  font-weight: 900;
  text-shadow: 0.5px 0 #646567, -0.5px 0 #646567, 0 0.5px #646567, 0 -0.5px #646567,
  0.5px 0.5px #646567, -0.5px -0.5px #646567, 0.5px -0.5px #646567, -0.5px 0.5px #646567;
}

.card-wrapper {
  position: relative;
  width: 100%;
  padding: 0 10px;
  /* espacio entre libros */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.card-wrapper:active,
.card-wrapper:hover,
.card-wrapper:focus {
  outline: none;
  border: none;
}

.cardBook {
  width: 100%;
  /* ancho del libro */
  height: 320px;
  /* alto del libro */
  overflow: hidden;
  cursor: pointer;
  position: relative;
  background-color: #f1e6e0;
  box-shadow: 5px 3px 2px #646567;
}

.cardBook .card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.cardBook .card-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

/*ALTO DE HOVER IMAGEN*/
.cardBook:hover .card-image {
  opacity: 0.2;
}

/* detalles al hover */
.details {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 15px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  align-items: center;
  background-color: #000;
  color: white !important;
  font-size: 14px;
}

.notBold {
  font-weight: normal !important;
}

.details h2 {
  margin: 15px 0;
  padding: 0;
  text-align: center;
  font-size: 15.5px;
  font-weight: bold;
}

.carousel .slick-dots {
  bottom: -2.5vw;
}

.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  border-radius: 20px;
}

.carousel .slick-dots li.slick-active button,
.carousel .slick-dots li:hover button {
  border-color: #d12421;
}

.carousel .slick-dots li.slick-active button:before,
.carousel .slick-dots li:hover button:before {
  background-color: #d12421;
  opacity: 1;
}

.carousel .slick-prev,
.carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #000;
}

.carousel .slick-prev:hover,
.carousel .slick-prev:focus,
.carousel .slick-next:hover,
.carousel .slick-next:focus {
  color: #fff;
  outline: none;
  background: #fff;
}

.slick-prev:before,
.slick-next:before {
  color: black !important;
  font-size: 25px !important;
}

.icnBook {
  margin-bottom: 10px;
}

.divBook .arrow-left {
  margin-right: 30px;
  position: absolute;
  display: inline-block;
  cursor: pointer;
  right: 0 !important;
  top: 0;
}

.divBook .arrow-right {
  position: absolute;
  display: inline-block;
  cursor: pointer;
  top: 0 !important;
  right: 0;
}

@media only screen and (max-width: 520px) {
  .cardBook {
    height: 300px;
    /* alto del libro */
    width: 230px;
    margin: 0 auto;
  }

  .arrow-left {
    margin-top: 3% !important;
  }

  .arrow-right {
    margin-top: 3% !important;
  }

  .divBook {
    padding-top: 2%;
  }

  .divBook .card-wrapper {
    padding-top: 15%;
  }

  .details {
    padding-top: 90px;
  }

  .details {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 15px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    align-items: center;
    background-color: #000;
    color: white !important;
  }
}

