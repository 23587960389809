.form-check-input:checked {
  background-color: #d12421 !important;
  border-color: transparent !important;
}

.btnSubmit {
  background-color: #d12421 !important;
  border-color: transparent !important;
}

@media only screen and (max-width: 1200px) {
  #colNormas {
    padding-top: 40px;
  }
}

.cardResources {
  width: 300px;
  background: white;
  padding: 0.4em;
  border-radius: 6px;
  display: inline-block;
}

.card-image-resources {
  background-color: rgb(236, 236, 236);
  width: 100%;
  height: 130px;
  border-radius: 6px 6px 0 0;
}

.card-image-resources:hover {
  transform: scale(0.98);
}

.category {
  color: #d12421;
  padding: 10px 7px 0;
}

.category:hover {
  cursor: pointer;
}

.cardTutsRes{
  border: none !important;
}

@media only screen and (max-width: 480px) {
  .divResources {
    margin-left: 50px;
  }
}
