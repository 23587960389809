.rowRepositories {
  background-repeat: repeat;
  background-size: cover;
  background-color: #d2091e;
  background-blend-mode: lighten;
  position: relative;
  height: auto;
}

.hrefRepo,
.hrefRepo:hover {
  color: #646567;
}

.hrefRepo:hover .titleRepo {
  transform: scale(1.1);
}

.hrefRepo:hover .imgRepo,
.hrefRepo:hover .imgRepoInv,
.hrefRepo:hover .imgBiblio {
  transform: scale(1.1);
}

.hrefRepo,
body {
  overflow-x: hidden;
}

@media (min-width: 1100px) and (max-width: 1369px) {
  .imgRepo,
  .journal,
  .imgBiblio {
    padding-top: 25px !important;
  }
}

@media (max-width: 760px) {
  .imgRepoInv,
  .imgRepo,
  .journal,
  .imgBiblio {
    height: 35% !important;
    width: 35% !important;
  }
}

@media (max-width: 576px) {
  .imgRepoInv,
  .imgRepo,
  .journal,
  .imgBiblio {
    height: 85% !important;
    width: 85% !important;
  }
}

.tooltipRepos {
  background-color: #646567 !important;
  padding: 40px !important;
  max-width: 400px !important;
  font-weight: bold !important;
  z-index: 3 !important;
}
