#containerInv {
  background-image: url("/src/assets/images/investigation/bg-investigacion.png");
  width: 100% !important;
  height: 350px !important;
  background-repeat: no-repeat !important;
}

#containerInv #tituloInv {
  background-color: #646567;
  color: white;
  width: 570px;
  margin: 5%;
  border: 1px solid #d12421;
  position: absolute;
  font-size: 22px;
}

.cardInformacion {
  border-bottom: 1px !important;
  border-bottom: 2px dotted !important;
  border-bottom: medium solid #d12421 !important;
  margin: auto !important;
}

.hrefInv {
  color: #d12421 !important;
  font-weight: bold;
}

.spanText {
  color: #646567 !important;
}

.cardInformacion,
.list-group-item {
  background-color: #fff !important;
}

.contents {
  white-space: pre-line;
}

.hrefTalleres {
  cursor: pointer;
  color: #d12421;
}

.divNormas {
  padding-bottom: 14.5rem;
}

.badgeResources {
  height: 50px !important;
  width: 190px !important;
}

.cardTutInv {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.infoTutC {
  position: absolute;
  bottom: -10px;
}

@media only screen and (max-width: 480px) {
  #containerInv {
    height: 110px !important;
    background-color: #d12421 !important;
    width: 100% !important;
  }

  .divNormas {
    padding-bottom: 0 !important;
  }

  #containerInv #tituloInv {
    width: auto;
    margin: 10%;
    border: 1px solid #d12421;
    position: absolute;
    font-size: 16px;
  }

  .bannerSAI {
    height: 100px;
  }

  .react-slideshow-fadezoom-wrapper {
    height: 30vh !important;
  }

  .marginDiv {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .nav-tabs {
    flex-direction: column !important;
  }
  
  .nav-item {
    margin: 10px 0 !important; 
  }

}

/* CSS PLAGIO
 */
.accordion-button:not(.collapsed) {
  background-color: #e8e8e8 !important;
}

.invDev {
  background-color: #fff;
  color: #697e91;
}

.invDev strong {
  font-weight: 600;
  color: #425275;
}

.invDev .inner {
  align-items: center;
  padding: 20px;
  padding-top: 40px;
  background-color: #fff;
  position: relative;
}

.invDev .title {
  font-weight: 600;
  font-size: 1.25rem;
  color: #425675;
}

.invDev .title + * {
  margin-top: 0.75rem;
}

.invDev .info + * {
  margin-top: 1rem;
}

.invDev .features {
  display: flex;
  flex-direction: column;
}

.invDev .features li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.invDev .features li + * {
  margin-top: 0.75rem;
}

.invDev .features .icon {
  background-color: #d12421;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.invDev .features + * {
  margin-top: 1.25rem;
}

.cardCons {
  background-color: #fff;
  padding: 1rem;
}

.header {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.iconPlagio {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background-color: #d12421;
  padding: 0.5rem;
  color: rgba(255, 255, 255, 1);
}

.iconPlagio svg {
  height: 2rem;
  width: 2rem;
}

.titleInv {
  font-weight: 600;
  font-size: 1.25rem;
  color: rgba(107, 114, 128, 1);
}

.infoP {
  margin-top: 1rem;
  color: rgba(107, 114, 128, 1);
}

.listGroup {
  background-color: white !important;
}

/* CSS PLAGIO */

.titTut {
  font-size: 18px !important;
}

.invDevs {
  border-radius: 16px;
  background-color: #fff;
  color: #697e91;
}

.invDevs strong {
  font-weight: 600;
  color: #425275;
}

.invDevs .inner {
  align-items: center;
  padding: 20px;
  padding-top: 40px;
  background-color: #fff;
  position: relative;
  border-width: 1px;
  border-color: rgba(219, 234, 254, 1);
  border-radius: 1rem;
}

.invDevs .info + * {
  margin-top: 1rem;
}

.invDevs .features {
  display: flex;
  flex-direction: column;
}

.invDevs .features li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.invDevs .features li + * {
  margin-top: 0.75rem;
}

.invDevs .features .icon {
  background-color: #d12421;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.invDevs .features + * {
  margin-top: 1.25rem;
}

.nav-link {
  color: #d12421 !important;
}

.nav-link.active {
  color: black !important;
}
/* nav-link active */

.navT {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px 20px;
  position: relative;
}

#tabHA-tab-IA,
#tabHA-tab-GB,
#tabHA-tab-NE,
#tabHP-tab-Pl,
#tabHP-tab-PGD,
#tabHP-tab-Pr,
#tabHP-tab-RD,
#tabHP-tab-RP,
#tabHP-tab-Cap {
  border: 0 !important;
}

#tabHA-tab-IA.active,
#tabHA-tab-GB.active,
#tabHA-tab-NE.active,
#tabHP-tab-Pl.active,
#tabHP-tab-PGD.active,
#tabHP-tab-Pr.active,
#tabHP-tab-RD.active,
#tabHP-tab-RP.active {
  border-bottom: 2px solid #d12421 !important;
}

.tab-content {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

