.cardNew {
  display: block;
  top: 10px;
  position: relative;
  max-width: 262px;
  background-color: #fff;
  border-radius: 4px;
  margin: 0px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  box-shadow: 10px 4px 8px rgba(38, 38, 38, 0.2);
  border: 1px solid #fff !important;
  height: 365px !important;
}

.newsletters {
  margin-left: 50px !important;
}

.cardTitleNews,
.cardDivNews {
  padding-top: -3% !important;
}

.imgNews {
  height: 140px !important;
}

@media only screen and (max-width: 520px) {
  .divNew {
    padding-top: 50px;
  }
  .slick-track {
    position: relative !important;
  }
}

.cardNewLibrary {
  min-height: 460px !important;
  width: 255px;
}

.titNewsletters {
  font-size: 16px !important;
}

.leaflet-container {
  z-index: 0;
  position: relative !important;
}

@media only screen and (max-width: 1450px) {
  .marginNews {
    margin-top: 5% !important;
  }
}


.footerSede{
  position: absolute; /* Posiciona el elemento arriba del contenedor */
  bottom: 50px; /* Ajusta la distancia desde la parte inferior del contenedor */
  z-index: 2;
  text-align: center;
}

.footerSingular {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px; 
  text-align: center;
  line-height: 60px; 
}