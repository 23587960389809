#containerReqWsh {
  background-image: url("/src/assets/images/request/workshop.png");
  width: 100% !important;
  height: 350px !important;
  background-repeat: no-repeat !important;
  background-size: cover;
}

#containerReqWsh #tituloReq {
  background-color: #646567;
  color: white;
  width: 570px;
  margin: 5%;
  border: 1px solid #d12421;
  position: absolute;
  font-size: 22px;
}

@media only screen and (max-width: 480px) {
  #containerReqWsh {
    height: 110px !important;
    background-color: #d12421 !important;
    width: 100% !important;
  }
  #containerReqWsh #tituloReq {
    width: auto;
    margin: 10%;
    border: 1px solid #d12421;
    position: absolute;
    font-size: 16px;
  }
}

.form-control.border-success {
  border-color: green !important;
}

.form-control.border-danger {
  border-color: red !important;
}

.swal2-tabs-container .swal2-tab-btn {
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  padding: 5px 10px;
  cursor: pointer;
}

.swal2-tabs-container .swal2-tab-btn:hover {
  background-color: #e6e6e6;
  border-color: #aaa;
}

.swal2-tabs-container .swal2-tab {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  background-color: #fff;
}

.swal2-articles,
.swal2-tab-btn {
  font-family: "Monospace" !important;
  font-style: normal !important;
  font-weight: normal !important;
}

#right-tabs-doi-tabpane-first,
#right-tabs-doi-tabpane-second {
  border: 1px solid #ccc;
}

.borderLeftDoi {
  border-left: 1px solid #ccc;
}

.nav .nav-item .tab-active-f {
  background-color: #646567 !important;
  color: white !important;
}

.nav .nav-item .tab-default-f {
  background-color: #fff !important;
}

.nav .nav-item .tab-active-d {
  background-color: #fff !important;
}

.nav .nav-item .tab-default-d {
  background-color: #646567 !important;
  color: white !important;
}

#datosContacto{
  border-bottom: 1px solid #ccc;
}
