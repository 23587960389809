.divSpaceLibrary {
  padding-top: 100px;
}

.imageLibrary {
  margin-top: 3rem;
  border: 3px solid #555;
}

.divDescLibrary {
  margin-top: 5rem;
}

.colHorarios {
  margin: 2rem;
}

@media only screen and (max-width: 520px) {
  .divDescLibrary {
    margin-top: 2rem;
  }

  .colHorarios {
    margin: 1rem;
  }
}

.ul-vertical {
  list-style: none;
  margin: 0;
  padding: 8px;
  background-color: #bbb;
  display: inline-block;
}

.btnHorarios {
  background-color: #d12421 !important;
}

.fontHr {
  margin: 5px;
}

.animate-ping {
  margin-right: 5px;
}

.divHorario {
  background-color: #fafafa;
  text-align: center;
}

.btnHorarios:hover {
  transform: scale(1.1);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.imgHorario {
  margin: 0px auto;
}

/* DIVS HORARIOS EN MODAL */
.bgHorario {
  background-color: #f7f4f4;
}

.bgHorario:hover {
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
}

/* REDES SOCIALES */
.wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.wrapper .iconRss-fb {
  color: #4867aa;
  cursor: pointer;
  text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.4);
  transition: all ease-in-out 150ms;
}

.wrapper .iconRss-fb:hover {
  transform: scale(1.1);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.flow-root {
  width: auto !important;
  margin-left: -15px !important;
}

.wrapper .iconRss-tw {
  color: #1da1f2 !important;
  cursor: pointer;
  text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.4);
  transition: all ease-in-out 150ms;
}

.wrapper .iconRss-tw:hover {
  transform: scale(1.1);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.wrapper .iconRss-tw {
  color: #444;
  cursor: pointer;
  text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
  transition: all ease-in-out 150ms;
}

.wrapper .iconRss-insta:hover {
  transform: scale(1.1);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.wrapper .iconRss-insta {
  color: #d12421;
  cursor: pointer;
  text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.4);
  transition: all ease-in-out 150ms;
}

.wrapper .iconRss-insta:hover {
  transform: scale(1.1);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

#progMejoramiento {
  background: rgb(193, 16, 13);
  background: linear-gradient(90deg,
      rgba(209, 36, 33, 1) 0%,
      rgba(209, 36, 33, 1) 35%,
      rgba(209, 36, 33, 1) 100%);
}

@media only screen and (max-width: 480px) {
  .wrapper {
    display: flex;
    align-items: start !important;
    justify-content: start !important;
  }

  .flow-root {
    margin-left: -1em !important;
  }

  .colNew{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5%;
  }
}

#main{
  margin-top: -5% !important;
}

.border-transparent{
  border-color: #CF9593 !important;
}

.cardUnit{
  min-height: 350px !important;
}

.iconUnit{
  font-size: 40px;
}

.cardNewLibrary{
  display: block;
  top: 10px;
  position: relative;
  max-width: 302px;
  background-color: #fff;
  border-radius: 4px;
  margin: 0px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  box-shadow: 10px 4px 8px rgba(38, 38, 38, 0.2);
  border: 1px solid #fff !important;
  height: 400px !important;
}

.divManag{
  box-shadow: 2px 4px 8px rgba(38, 38, 38, 0.2);
}

.contentInfoUS {
  background-color: white;
  padding: 20px;
}

.cardTitleUS {
  color: #555;
  font-size: 1.3rem;
}