@media screen and (max-width: 480px) {
  .swal2-title {
    font-size: 25px !important;
  }

  .swal2-image {
    margin-left: auto;
    margin-right: auto;
    max-width: 300px !important;
  }

  .swal2-html-container {
    font-size: 17px !important;
    font-weight: bold !important;
    font-family: "Ubuntu" !important;
  }
}

.swal2-html-container {
  font-family: "Ubuntu" !important;
  font-style: oblique !important;
  font-weight: bold !important;
  font-size: 20px !important;
}

.toast2 {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  color: white;
  font-size: 10px;
}

.toast2 img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.toast2 h5 {
  font-weight: bold;
  margin-bottom: 5px;
  padding-top: 20px;
  font-size: 15px;
}

.toast2 p,
.enlaceToast {
  font-size: 14px;
  color: white;
}

.enlaceToast :hover {
  color: #e66b80;
}
.toast2 .close {
  padding-bottom: 23%;
  font-size: 12px;
}

.iconToast {
  color: #e66b80;
}
