.eb-search-container > * {
  flex: 1 100%;
  padding: 12px;
}
.eb-search-container__title {
  font-weight: 400;
  font-size: 1.5em;
  letter-spacing: -0.05em;
  text-align: center;
}
.eb-search__box > * {
  flex: 1 100%;
  line-height: normal;
}
.eb-search__box {
  border-radius: 0.25em;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.1em;
}
.eb-search__input-bquery {
  background: #fff;
  border: 1px solid #d2091e;
  border-bottom-color: transparent;
  border-radius: 0.25em 0.25em 0 0;
  padding: 0.75em;
}
.eb-search__input-bquery:focus {
  color: #333;
}
.eb-search__submit-button {
  -webkit-appearance: button;
  background: #d2091e;
  border: 1px solid #d2091e;
  border-radius: 0 0 0.25em 0.25em;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  color: #ffffff;
  cursor: pointer;
  overflow: visible;
  padding: 0.5em 1.25em;
  text-align: center;
  white-space: nowrap;
}
.eb-search__submit-text {
  display: inline-flex;
  overflow: hidden;
}
.eb-filter-container {
  padding-bottom: 0;
  display: flex;
  flex-wrap: wrap;
}

.eb-search__radio {
  border: none;
  padding: 0;
  display: flex;
  flex-direction: row;
}
.eb-search__boxed_label {
  padding: 12px;
}
.eb-adv-search {
  padding: 6px;
  padding-right: 1em;
  text-align: right;
  white-space: nowrap;
}
.eb-adv-search a.eb-adv-search__link {
  color: #000000;
  text-decoration-skip-ink: auto;
  font-weight: 600;
}
.eb-sr-only {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
.eb-search-container__has-select .eb-search__search-prefix {
  background-color: #fff;
  border-radius: 0.25em 0 0 0.25em;
  margin-bottom: 0.75em;
  position: relative;
}
.eb-search-container__has-select .eb-search__search-prefix:after,
.eb-search__search-prefix:before {
  bottom: 0;
  content: "";
  pointer-events: none;
  position: absolute;
  top: 0;
}
.eb-search-container__has-select .eb-search__search-prefix:before {
  right: 0;
  width: 1.75em;
}
.eb-search-container__has-select .eb-search__search-prefix:after {
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  height: 0.5em;
  margin: auto;
  right: 0.75em;
  transform-origin: center right;
  transform: rotate(45deg);
  width: 0.5em;
  z-index: 2;
}

.eb-search-container__has-select .eb-search__select {
  -webkit-appearance: none;
  appearance: none;
  background: #fff;
  border: 1px solid #d2091e;
  border-radius: 0.25em;
  box-shadow: none;
  color: #000;
  height: 100%;
  padding: 0.5em 1.75em 0.5em 0.75em;
  width: 100%;
}
.eb-search-container__has-select .eb-search__select:focus {
  color: #000;
}
.eb-search-container__has-select .eb-search__select::-ms-expand {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .eb-search-container {
    padding: 0.75em;
  }
  .eb-search__box {
    flex-wrap: nowrap;
    height: 2.8em;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }
  .eb-search__input-bquery {
    border-bottom-color: #d12421;
    border-radius: 0.25em 0 0 0.25em;
    border-right-color: transparent;
    width: 100%;
  }
  .eb-search__submit-button {
    border-radius: 0 0.25em 0.25em 0;
    box-shadow: none;
    flex: 0 0 auto;
  }
  .eb-filter-container {
    flex: 1;
    padding-bottom: 0.75em;
    padding-top: 0em;
  }
  .eb-search__label {
    width: auto;
  }
  .eb-search-container__has-limiter .eb-adv-search {
    flex: 0 0 auto;
  }
  .eb-search-container__has-select .eb-search__search-prefix {
    flex: 1 0 auto;
    margin-bottom: 0;
  }
  .eb-search-container__has-select .eb-search__select {
    flex: 0 0 auto;
    border-radius: 0;
  }
  .eb-search-container__has-select
    .eb-search__search-prefix:nth-of-type(1)
    .eb-search__select {
    border-radius: 0.25em 0 0 0.25em;
  }
  .eb-search-container__has-select .eb-search__input-bquery {
    border-left-color: transparent;
    border-radius: 0;
  }
  .eb-search-container__has-select-two
    .eb-search__search-prefix:nth-of-type(1)
    > .eb-search__select {
    border-right-color: transparent;
  }
  html[dir="rtl"] .eb-search__input-bquery {
    border-radius: 0 0.25em 0.25em 0;
    border-left-color: transparent;
    border-right-color: inherit;
  }
  html[dir="rtl"] .eb-search__submit-button {
    border-radius: 0.25em 0 0 0.25em;
    border-left-color: inherit;
    border-right-color: transparent;
  }
  html[dir="rtl"] .eb-search__select {
    border-radius: 0 0.25em 0.25em 0;
    border-right-color: inherit;
  }
  html[dir="rtl"] .eb-search-container__has-select .eb-search__input-bquery {
    border-radius: 0;
    border-right-color: transparent;
  }
  html[dir="rtl"]
    .eb-search-container__has-select
    .eb-search__search-prefix:nth-of-type(1)
    .eb-search__select {
    border-radius: 0 0.25em 0.25em 0;
  }
  html[dir="rtl"]
    .eb-search-container__has-select-two
    .eb-search__search-prefix:nth-of-type(1)
    > .eb-search__select {
    border-left-color: transparent;
    border-right-color: inherit;
  }
  html[dir="rtl"]
    .eb-search-container__has-select-two
    .eb-search__search-prefix:nth-of-type(2)
    > .eb-search__select {
    border-radius: 0;
  }
  html[dir="rtl"] .eb-search__radio_label {
    padding-left: 0.5em;
    padding-right: 0;
  }
}

.linkRepos {
  color: #d12421;
  font-weight: bold !important;
}

#cardEds {
  height: auto !important;
  background-color: #f0f0f0 !important;
}

.rspEds {
  display: none !important;
}

.searchEds {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
}

.linkRepos:hover {
  color: #646567;
}

#cardEds .card-header,
.enlaceMiCuenta {
  background-color: #d2091e !important;
}

@media only screen and (min-width: 540px) {
  .divLeft {
    margin-top: -10px !important;
  }
}

.bannerHome {
  height: 0px !important;
}

.eb-search__radio_input {
  --s: 1em;
  --c: #d2091e;

  height: var(--s);
  aspect-ratio: 1;
  border: calc(var(--s) / 8) solid #939393;
  padding: calc(var(--s) / 8);
  background: radial-gradient(farthest-side, var(--c) 94%, #0000) 50%/0 0
    no-repeat content-box;
  border-radius: 50%;
  outline-offset: calc(var(--s) / 10);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-size: inherit;
  transition: 0.3s;
}
.eb-search__radio_input:checked {
  border-color: var(--c);
  background-size: 100% 100%;
}

.check__eds {
  width: 15px;
  height: 15px;
  cursor: pointer;
  accent-color: red;
}

@media only screen and (min-width: 768px) {
  #cardEds {
    margin-top: 5%;
    border: 0 !important;
  }
  .avanzada {
    display: flex;
    justify-content: flex-end; /* Alinea el botón a la derecha */
    align-items: flex-end; /* Alinea el botón al final del div verticalmente */
    margin-top: 5% !important;
  }
}
/* CIERRE ESTILOS CATALOGO */

/* ESTILOS EDS */
.eb-search__span,
.eb-search__radio_label_text {
  font-size: 17px;
}

/* NUEVOSSS */
.ebsco-search-box {
  padding: 2rem;
}

.ebsco-search-box .eb-search__submit-button {
  font-size: 1.25em;
}

.ebsco-search-box * {
  transition: all 0.3s ease-in-out 0ms;
}

.ebsco-search-box__search:has(:focus-within) {
  border-color: #3e75cf;
  box-shadow: 0 0 0 1px #3e75cf, 0 0 0 4px #d8e3f5;
}

.ebsco-search-box__search {
  color: #3d3f42;
  border: 1px solid #d2091e;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  margin-top: -1%;
}

.ebsco-search-box__search__input {
  border: none;
  outline: none;
  flex-grow: 1;
  padding-block: 0.75rem;
  padding-inline-start: 1rem;
  border-radius: 4px;
  font-size: 1.1rem;
  color: #3d3f42;
}

.ebsco-search-box__search__button {
  border: none;
  outline: none;
  background-color: transparent;
  padding-inline: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #d12421;
}

.ebsco-search-box__search__button:hover {
  color: #d12421;
}

.ebsco-search-box__search__button > svg {
  width: 1.25rem;
  height: 1.25rem;
}

.ebsco-search-box__extras {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.ebsco-search-box__extras__advanced {
  margin-top: -10px !important;
  color: #d12421;
  text-decoration-skip-ink: auto;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  text-decoration: none;
  border: 1px solid;
  border-color: transparent;
}

.ebsco-search-box__extras__advanced:hover,
.ebsco-search-box__extras__limiters__limiter:hover > label {
  color: #646567 !important;
}

.ebsco-search-box__extras__limiters {
  display: flex;
  gap: 1rem;
}

.ebsco-search-box__extras__limiters__limiter > label {
  margin-left: 5px;
  font-size: 17px !important;
  color: #646567 !important;
}

.separador {
  font-style: normal !important;
}

@media (max-width: 1451px) {
  .ebsco-search-box__extras__limiters {
    display: inline;
    padding: 0 !important;
  }

  .ebsco-search-box__extras {
    display: inline;
    justify-content: space-between;
  }
}

.containerEds {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.divRight {
  text-align: right;
}

.consulta__prestamo {
  padding-top: 40px;
}

.eb-search__radio_label_text {
  color: #646567 !important;
}

.eb-search__radio_input {
  --s: 1em; /* control the size */
  --c: #d12421; /* the active color */

  height: var(--s);
  aspect-ratio: 1;
  border: calc(var(--s) / 8) solid #939393;
  padding: calc(var(--s) / 8);
  background: radial-gradient(farthest-side, var(--c) 94%, #0000) 50%/0 0
    no-repeat content-box;
  border-radius: 50%;
  outline-offset: calc(var(--s) / 10);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-size: inherit;
  transition: 0.3s;
}
.eb-search__radio_input:checked {
  border-color: var(--c);
  background-size: 100% 100%;
}

.check__eds {
  width: 15px;
  height: 15px;
  cursor: pointer;
  accent-color: red; /* Modern browsers support this for changing the checkbox color */
}

.eb-search__radio_label_text {
  padding-left: 6px;
}

@media (min-width: 2000px) {
  .rowEds {
    width: auto !important;
  }
}

.responsive-options {
  width: 100% !important;
}

@media (max-width: 1200px) {
  .responsive-options {
    grid-template-columns: repeat(2, 1fr);
  }
  .ebsco-search-box__extras__advanced,
  .miCuenta {
    display: flex !important;
    justify-content: flex-start !important;
  }

  .miCuentaLocate,
  .miCuenta {
    display: flex !important;
    justify-content: flex-start !important;
    float: left !important;
  }
}

@media (min-width: 1200px) {
  .miCuenta {
    float: right !important;
  }
}

@media (max-width: 480px) {
  .responsive-options {
    width: 100% !important;
    grid-template-columns: 1fr;
  }

  #searchInput,
  #eb-sbb-search-input {
    font-size: 14px !important;
  }
}

.ebsco-search-box__extras__advanced,
.miCuenta {
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 1452px) and (max-width: 1670px) {
  .ebsco-search-box__extras__limiters {
    display: inline;
    padding: 0 !important;
  }
}

#tabs-recursos-tab-rec {
  width: 100% !important;
}

.footerEDS {
  background-color: #e8e4e4; /* Color de fondo del footer */
  padding: 10px; /* Espaciado interno */
  text-align: center; /* Centra el texto */
  position: relative;
  bottom: 0;
  width: 100%;
  font-weight: bold;
  color: #646567;
  font-size: 18px;
}

.enlaceAdvance {
  margin-top: -1px !important;
}

.enlaceAdv {
  float: left !important;
}
