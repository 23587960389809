@font-face {
  font-family: "Ubuntu" !important;
  src: local("Ubuntu"), url(./fonts/Ubuntu-Bold.ttf) format("ttf") !important;
}

.App {
  text-align: center;
}

#root,
#basic-button,
.css-1gahtff,
.modal-content {
  font-family: "Montserrat" !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.barnav .text-white,
.text-gray-300 {
  font-size: 15px !important;
}

.enlace {
  color: #d12421 !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#bannerSibucsc {
  position: relative !important;
  min-height: 530px;
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat; 
}

.ifm .viewer-logo {
  display: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 520px) {
  #bannerSibucsc {
    display: none;
  }
}

.card-title {
  font-weight: bold !important;
}

.sbuttons {
  top: -4em;
  position: absolute;
  margin: 7em;
  right: 0;
  z-index: 10;
}

.sbutton {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  color: white;
  margin: 20px auto 0;
  box-shadow: 0px 5px 11px -2px rgba(0, 0, 0, 0.18),
    0px 4px 12px -7px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  position: relative;
}

.sbutton > i {
  font-size: 38px;
  line-height: 60px;
  transition: all 0.2s ease-in-out;
  transition-delay: 2s;
}

[tooltip]:hover:before,
[tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}

.sbutton.mainsbutton {
  background: #2ab1ce;
}

.sbutton.gplus {
  background: #f44336;
}

.sbutton.instagram {
  background: #e34d70;
}

.sbutton.twitt {
  background: #03a9f4;
}

.sbutton.fb {
  background: #3f51b5;
}

.sbutton.yt {
  background: #ff0000;
}

.sbutton.tiktok {
  background: #000;
}

.iconRss {
  margin-top: 15%;
}

svg:hover path {
  fill: #646567;
}

.validationTesis {
  color: #d12421 !important;
}

.ahrefTwitt:hover #svgTwitt path {
  fill: #646567 !important;
}

#bannerSIBUCSC {
  -webkit-clip-path: polygon(
    0 0,
    5760px 0,
    5760px calc(100% - 352px),
    0 100%
  ) !important;
  clip-path: polygon(
    0 0,
    5760px 0,
    5760px calc(100% - 352px),
    0 100%
  ) !important;
  background-color: #646567;
  width: 100%;
  min-height: 350px;
}

#imgBannerReq {
  margin-top: -20px;
  height: 300px;
  width: 300px;
}

#imgBannerDigital {
  margin-top: -45px;
  height: 370px;
  width: 370px;
}

#imgBanner {
  margin-top: 5%;
  width: 400px;
  border: 3px solid #555;
}

@media only screen and (max-width: 768px) {
  .sbuttons {
    display: none !important;
  }

  #bannerSIBUCSC {
    min-height: 500px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  #bannerSIBUCSC {
    min-height: 550px;
  }

  .bannerHome {
    height: 620px !important;
  }
}

.p-highlight {
  color: red !important;
  font-weight: bold;
}

.web-container {
  display: block;
  margin: 0 auto !important;
  width: 100%;
  max-width: 1920px;
}

.navSubMenu {
  margin-top: 1px !important;
  font-family: "Montserrat" !important;
  font-size: 16px !important;
}

.navMenu {
  color: #646567 !important;
  font-family: "Montserrat" !important;
  font-size: 16px !important;
}

.sizeMenuDesp {
  font-size: 16px !important;
}

.activeMovil {
  color: #d12421 !important;
}

@media only screen and (min-width: 912px) and (max-width: 912px) {
  .navSubMenu,
  .navMenu {
    font-family: "Montserrat" !important;
    font-size: 12px !important;
  }
}

.bannerHome {
  height: 550px;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 884px) {
  .bannerHome {
    height: 650px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .navMenu, .navSubMenu {
    font-size: 15px !important;
  }
}
