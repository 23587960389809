.main-footer {
  color: white;
  background-color: #d2091e;
  padding-top: 3rem;
  position: relative;
}

.h5,
h6 {
  padding-top: 5px;
}

.listRss {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}
