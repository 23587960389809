.cardInformation:hover {
  border: 1px solid #b7b7b7;
}

.cardInformation {
  background-color: red;
}

.bgInformation {
  background-color: #d2091e;
}

.cardTitleInfo {
  color: white;
  font-size: 1.3rem;
}

.titleInfoBoletines,
.titleInfoEvents,
.titleInfoNormativas,
.titleInfoBD,
.titleInfoInv {
  color: #646567;
  font-size: 1.8rem;
}

.titleInfoInteres {
  color: white;
  font-size: 1.8rem;
}

.titleInfoRepos {
  color: #fff;
  font-size: 1.8rem;
}

#imgReglamentos {
  width: 70%;
}

.titleReglamento {
  color: #646567;
  font-weight: bold;
}

.cardReglamento {
  border: none !important;
}

.contentInfoInt {
  background-color: #d2091e;
  padding: 20px;
}

.footerInfoInt {
  background-color: d2091e;
  text-align: center;
}

.btnInteres {
  color: white !important;
  border: 2px solid white !important;
}

@media only screen and (min-width: 1200px) {
  .marginCardReg {
    margin-left: -4rem !important;
  }
}

@media only screen and (max-width: 520px) {
  .titleInfoInteres, .titleInfoNormativas {
    margin-left: 70px !important;
  }
  /* css para quitar un reglamento */
  #imgReglamentos {
    margin-top: 7% !important;
  }

  .titleInfoRepos {
    text-align: center;
    padding: 0px; /* Espacio alrededor del texto */
    font-size: 1.6rem;
  }
}
